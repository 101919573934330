import React from 'react';
import Layout from '../../components/Layout';
import Title from "../../components/Title";
import Metas from "../../components/Metas";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";

const AlertesSMS = ({ pageContext }) => {
    const {
        breadcrumb: {crumbs},
    } = pageContext

    const lastCrumb = crumbs[crumbs.length - 1];

    return (
        <Layout>
            <Metas title={"Alertes SMS"}/>
            <section className="section page-breadcrumb is-small has-background-light">
                <div className="container">
                    <Breadcrumb
                        crumbs={crumbs}
                        crumbSeparator=" &rsaquo; "
                        crumbLabel={"Alertes SMS"}
                        disableLinks={[
                            lastCrumb.pathname
                        ]}
                    />
                </div>
            </section>
            <section className="section page-content">
                <div className="container">
                    <Title title={"Alertes SMS"} subtitle={"Inscrivez vous à nos listes d'envoi"} className={"has-text-centered"}/>
                    <iframe
                        width="540"
                        height="975"
                        src="https://414ac9b5.sibforms.com/serve/MUIEAANzD8Bn45P-2sMtPOg5GVk518gG36rV7uj4X9VDFMToFXLj_vGK9yWSM2DXUYuCRzy-wzXEvqL2L2ovc7pw96EH73qBDd9PrT3RUQVtgnwaQKDYyjr0_0ChitL0Ko38j-DUc-glHPOHIcGxowwSS4mywG-pkQ7ds4TD1up0QIegEuakOS3ThC7cj9Dl4_Gso56umXmXGIGa"
                        frameBorder="0"
                        scrolling="auto"
                        className="iframe"
                        allowFullScreen
                        style={{
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            maxWidth: '100%',
                        }}
                    >
                    </iframe>
                </div>
            </section>
        </Layout>
    );
};

export default AlertesSMS;